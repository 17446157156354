import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { requestLimits, setNewLimit } from '../../../modules/casino/store/actions/profile';

import './index.scss';
import { useMediaQuery } from '../../utils/useQueryMedia';

type LimitsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type Limit = {
  id: number | null;
  type: number | null;
  subtype: number | null | string;
  period_type: number | null;
  limit_amount: number | null;
  accumulated_amount: number | null;
  limit_start: number | null;
  limit_end: number | null;
  new_limit_amount: number | null;
};

const noDataLimit: Limit = {
  id: null,
  type: 1,
  subtype: null,
  period_type: 1,
  limit_amount: null,
  accumulated_amount: null,
  limit_start: null,
  limit_end: null,
  new_limit_amount: null,
};

type ModuleStateProps = {
  limitValue: number;
  limitType: number;
  hasLimitDaily: boolean;
  limitValueDaily: string;
  hasLimitWeekly: boolean;
  limitValueWeekly: string;
  hasLimitMonthly: boolean;
  limitValueMonthly: string;
  limitError: string | boolean;
  isConfirmationDialogOpen: boolean;
};

interface Hash {
  [key: string]: string;
}

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Limits = (componentProps: LimitsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile);
  const currency = useAppSelector((state) => state.currencies.currentCurrency);
  const marketing_agreements = useAppSelector((state) => state.alerts.settings.marketing_agreements);

  const isDesktop = useMediaQuery('(min-width:900px)');

  const ERRORS = {
    MINIMUM_LIMIT: 'DEPOSIT_AMOUNT_ERROR_MINIMUM_LIMIT',
    MAXIMUM_LIMIT: 'DEPOSIT_AMOUNT_ERROR_MAXIMUM_LIMIT',
  };

  const PERIOD_TYPE_DAILY = 1;
  const PERIOD_TYPE_WEEKLY = 2;
  const PERIOD_TYPE_MONTHLY = 3;
  const PERIOD_TYPE_KYC = 'KYC';

  const limitTypes = {
    daily: PERIOD_TYPE_DAILY,
    weekly: PERIOD_TYPE_WEEKLY,
    monthly: PERIOD_TYPE_MONTHLY,
    KYC: PERIOD_TYPE_KYC,
  };

  const initialState = {
    hasLimitDaily: false,
    limitValueDaily: '',
    hasLimitWeekly: false,
    limitValueWeekly: '',
    hasLimitMonthly: false,
    limitValueMonthly: '',
    currentStep: 'default',
    limitType: 0,
    limitValue: 20,
    limitError: false,
    isConfirmationDialogOpen: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const getLimit = (periodType: number | string | null) => {
    if (periodType === null) {
      return { ...noDataLimit, subtype: null };
    }

    if (profile && profile.limits && profile.limits[periodType] != null) {
      return profile.limits[periodType];
    }

    const def = { ...noDataLimit };
    def.subtype = periodType;
    return def;
  };

  const getLimitError = (value: number) => {
    const min = window.config.currency_settings?.DEFAULT?.DEPOSIT_LIMITS?.[currency]?.min ?? 0;
    const max = window.config.currency_settings.DEFAULT.DEPOSIT_LIMITS?.[currency]?.max ?? 0;

    return value < min ? ERRORS.MINIMUM_LIMIT : value > max ? ERRORS.MAXIMUM_LIMIT : false;
  };

  const onLimitButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
    // get data-value from button
    const value = el.getAttribute('data-value');

    // MainEventBus.emit('NOTIFICATION', {
    //   // title: 'Limita a fost modificată cu succes!',
    //   message: `You have been self-excluded for <br/><strong>${value}</strong>!`,
    //   type: 'success',
    // });

    if (value) {
      setState((v) => ({
        ...v,
        limitValue: Number(value),
        limitError: getLimitError(Number(value)),
      }));
    }
  };

  const getLimitButtonValues = () => {
    return [
      {
        value: 20,
        valueRendered: currency ? `20 ${currency}` : '20 RON',
        id: 1,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 20,
      },
      {
        value: 30,
        valueRendered: currency ? `30 ${currency}` : '30 RON',
        id: 2,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 30,
      },
      {
        value: 50,
        valueRendered: currency ? `50 ${currency}` : '50 RON',
        id: 3,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 50,
      },
      {
        value: 100,
        valueRendered: currency ? `100 ${currency}` : '100 RON',
        id: 4,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 100,
      },
      {
        value: 200,
        valueRendered: currency ? `200 ${currency}` : '200 RON',
        id: 5,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 200,
      },
      {
        value: 300,
        valueRendered: currency ? `300 ${currency}` : '300 RON',
        id: 6,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 300,
      },
      {
        value: 500,
        valueRendered: currency ? `500 ${currency}` : '500 RON',
        id: 7,
        onClickHandler: onLimitButtonClick,
        active: state.limitValue === 500,
      },
    ];
  };

  const limits = [];

  const contextValue = {
    ...state,
    limitDaily: { data: getLimit(limitTypes.daily) },
    limitWeekly: { data: getLimit(limitTypes.weekly) },
    limitMonthly: { data: getLimit(limitTypes.monthly) },
    limitKYC: { data: getLimit(limitTypes.KYC) },
    lowLimitStr: '',
    lowLimitSeted: '',
    lowestLimit: { data: getLimit(null) },

    //marketing_agreements

    smsAlert: marketing_agreements?.marketing_receive_sms === 1,
    emailAlert: marketing_agreements?.marketing_receive_email === 1,
    phoneAlert: marketing_agreements?.marketing_receive_phone === 1,
    partnersAlert: marketing_agreements?.marketing_partners === 1,

    // currency: wallet?.currency,
    currency: currency ? currency : 'RON',
    limitButtonValues: getLimitButtonValues(),
    hasLimitDaily: false,
    hasLimitWeekly: false,
    hasLimitMonthly: false,
    hasLimitKYC: false,
    limitError: state.limitError,
    isDesktop,
    onSaveLimit: () => {
      if (
        !isNaN(Number(state.limitValue)) &&
        state.limitValue &&
        profile &&
        profile.limits &&
        // @ts-ignore
        profile.limits[state.limitType] != null &&
        // @ts-ignore
        profile.limits[state.limitType].limit_amount != state.limitValue &&
        state.limitError === false
      ) {
        dispatch(setNewLimit(1, state.limitType, Number(state.limitValue)));
        setState((v) => ({
          ...v,
          currentStep: 'default',
          limitValue: initialState.limitValue,
        }));
      }
    },
    onChangeLimit: (e: React.ChangeEvent<HTMLInputElement>) => {
      const el = e.currentTarget ?? (e.currentTarget as HTMLInputElement);
      const value = el.value;

      // regex to check if value is a positive number
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        return;
      }

      if (value.toString().length > 10) {
        return;
      }

      setState((v) => ({
        ...v,
        limitValue: Number(value),
        limitError: getLimitError(Number(value)),
      }));
    },
    onRemoveLimit: () => {
      // @ts-ignore
      if (
        state.limitType &&
        profile.limits &&
        profile.limits[state.limitType] != null &&
        profile.limits[state.limitType].limit_amount != null
      ) {
        // already has limit so remove it
        dispatch(setNewLimit(1, state.limitType, -1));
        setState((v) => ({
          ...v,
          currentStep: 'default',
        }));
      } else {
        // set a limit
        setState((v) => ({
          ...v,
          currentStep: 'default',
        }));
      }
    },
    onShowLimitDailyValue: () => {
      setState((v) => ({
        ...v,
        currentStep: 'amount',
        limitType: limitTypes.daily,
        limitValue: initialState.limitValue,
        limitError: initialState.limitError,
      }));
    },
    onShowLimitWeeklyValue: () => {
      setState((v) => ({
        ...v,
        currentStep: 'amount',
        limitType: limitTypes.weekly,
        limitValue: initialState.limitValue,
        limitError: initialState.limitError,
      }));
    },
    onShowLimitMonthlyValue: () => {
      setState((v) => ({
        ...v,
        currentStep: 'amount',
        limitType: limitTypes.monthly,
        limitValue: initialState.limitValue,
        limitError: initialState.limitError,
      }));
    },
    onBackHandler: () => {
      setState((v) => ({
        ...v,
        currentStep: 'default',
        limitType: 0,
        limitDaily: { data: getLimit(limitTypes.daily) },
        limitWeekly: { data: getLimit(limitTypes.weekly) },
        limitMonthly: { data: getLimit(limitTypes.monthly) },
      }));
    },
    onToggleConfirmationDialog: () => {
      setState((v) => ({
        ...v,
        isConfirmationDialogOpen: !v.isConfirmationDialogOpen,
      }));
    },
  };

  React.useEffect(() => {
    dispatch(requestLimits());
  }, []);

  if (profile.limits && profile.limits['KYC'] != null && profile.limits['KYC'].limit_amount != null) {
    contextValue.hasLimitKYC = true;
    // contextValue.lowLimitStr = limitTypes.KYC;
    // contextValue.lowLimitSeted = limitTypes.KYC;
    limits.push({ data: profile.limits['KYC'] });
  }

  if (profile.limits && profile.limits[3] != null && profile.limits[3].limit_amount != null) {
    contextValue.hasLimitMonthly = true;
    // contextValue.lowLimitSeted = limitTypes.monthly.toString();
    // contextValue.lowLimitStr = 'Monthly';
    limits.push({ data: profile.limits[3] });
  }
  if (profile.limits && profile.limits[2] != null && profile.limits[2].limit_amount != null) {
    contextValue.hasLimitWeekly = true;
    // contextValue.lowLimitSeted = limitTypes.weekly.toString();
    // contextValue.lowLimitStr = 'Weekly';
    limits.push({ data: profile.limits[2] });
  }

  if (profile.limits && profile.limits[1] != null && profile.limits[1].limit_amount != null) {
    contextValue.hasLimitDaily = true;
    // contextValue.lowLimitSeted = limitTypes.daily.toString();
    // contextValue.lowLimitStr = 'Daily';
    limits.push({ data: profile.limits[1] });
  }

  if (limits.length > 0) {
    limits.sort((a, b) => a.data.limit_amount - b.data.limit_amount);
    const lowest = limits[0];
    contextValue.lowestLimit = limits[0];

    switch (lowest.data.period_type) {
      case 1:
        contextValue.lowLimitStr = 'Daily';
        contextValue.lowLimitSeted = limitTypes.daily.toString();
        break;
      case 2:
        contextValue.lowLimitStr = 'Weekly';
        contextValue.lowLimitSeted = limitTypes.weekly.toString();
        break;
      case 3:
        contextValue.lowLimitStr = 'Monthly';
        contextValue.lowLimitSeted = limitTypes.monthly.toString();
        break;
      case 'KYC':
        contextValue.lowLimitStr = 'KYC';
        contextValue.lowLimitSeted = limitTypes.KYC.toString();
        break;
      default:
        contextValue.lowLimitStr = '';
        contextValue.lowLimitSeted = '';
        break;
    }
  }

  // check if we have some errors to show
  if (state.limitValueDaily === 'step1') {
  }

  // console.log('DEBUG Limits', { contextValue, limits });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Limits;
