import React from 'react';

interface GameIframeProps {
  gameUrl: string;
  onIframeMessage?: (m: any) => void;
}

interface EGTMessage {
  command?: string;
  gin: string;
  type: string;
}

interface GreenTubeMessage {
  event?: {
    name: string;
  };
}

export enum GameActions {
  CloseGame,
  EgtLoadNewGame,
  EgtReloadCurrentGame,
}

const GameIframe = (props: GameIframeProps) => {
  const egtReceivedMessage = (event: MessageEvent<EGTMessage>) => {
    if (typeof props.onIframeMessage !== 'function') return;
    if (!event.data) return;
    if (import.meta.env.DEV) {
      console.log('GameIframe egtReceivedMessage', event.data);
    }
    if (event.data.command == null) return;

    if (
      'exit' === event.data.command ||
      'com.egt-bg.exit' === event.data.command ||
      'com.egt-bg.launchLobby' === event.data.command
    ) {
      return props.onIframeMessage({ command: GameActions.CloseGame });
    }

    if ('com.egt-bg.launchGame' === event.data.command) {
      try {
        const providerGameId = String(event.data.gin);
        return props.onIframeMessage({ command: GameActions.EgtLoadNewGame, data: providerGameId });
      } catch (e) {}
    }

    /*
    command: "openGame"
    type: "FZWBLSlot"
    */
    if ('openGame' === event.data.command) {
      try {
        const providerGameId = String(event.data.type);
        return props.onIframeMessage({ command: GameActions.EgtLoadNewGame, data: providerGameId });
      } catch (e) {}
    }

    if ('com.egt-bg.reload' === event.data.command) {
      return props.onIframeMessage({ command: GameActions.EgtReloadCurrentGame });
    }
  };
  const greentubeReceivedMessage = (event: MessageEvent) => {
    if (typeof props.onIframeMessage !== 'function') return;
    if (!event.data) return;

    let data: GreenTubeMessage = {};

    try {
      if (typeof event.data === 'string') {
        const tmp = JSON.parse(event.data);
        if (tmp != null) data = tmp;
      }
    } catch (e) {
      return;
    }

    if (data != null && typeof data.event !== 'undefined') {
      if ('gameClosing' === data.event.name) {
        return props.onIframeMessage({ command: GameActions.CloseGame });
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', egtReceivedMessage, false);
    window.addEventListener('message', greentubeReceivedMessage, false);

    return () => {
      window.removeEventListener('message', egtReceivedMessage, false);
      window.removeEventListener('message', greentubeReceivedMessage, false);
    };
  }, []);

  return (
    <iframe
      id="game-frame"
      src={props.gameUrl}
      onError={() => {
        console.log('[LAUNCHER] Game error!');
      }}
      width="100%"
      height="100%"
      //autoPlay
      allow={'autoplay'}
      title="Game Slot"
    />
  );
};

export default React.memo(GameIframe);
