import { greyScaleColor, increaseBrightness } from './color-utils';

const BRIGHTNESS = 50;
const FONT_FAMILY = 'Nudista , sans-serif';

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

const getString = (val, def) => {
  if (val) return val;
  return def;
};

const degrees_to_radians = (degrees) => {
  var pi = Math.PI;
  return degrees * (pi / 180);
};

const filter_degree = (d) => {
  while (d < 0) {
    d += 360;
  }
  return d % 360;
};

function CanvasWheel(ctx) {
  this.ctx = ctx;
  this.pixelRatio = 1;
  this.blinkBrightness = BRIGHTNESS;
}

CanvasWheel.prototype.setBlinkBrightness = function (brightness) {
  this.blinkBrightness = brightness;
};

CanvasWheel.prototype.calculatePixelRatio = function () {
  const dpr = window.devicePixelRatio || 1;
  const bsr =
    this.ctx.webkitBackingStorePixelRatio ||
    this.ctx.mozBackingStorePixelRatio ||
    this.ctx.msBackingStorePixelRatio ||
    this.ctx.oBackingStorePixelRatio ||
    this.ctx.backingStorePixelRatio ||
    1;

  this.pixelRatio = dpr / bsr;
};
CanvasWheel.prototype.updateSize = function (canvas, w, h) {
  canvas.width = w * this.pixelRatio;
  canvas.height = h * this.pixelRatio;
  canvas.style.width = w + 'px';
  canvas.style.height = h + 'px';
  this.ctx.setTransform(this.pixelRatio, 0, 0, this.pixelRatio, 0, 0);
};

CanvasWheel.prototype.clear = function () {
  this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
};

CanvasWheel.prototype.drawSector = function (cx, cy, radius, angleStart, angleEnd, color, strokeColor) {
  const radiansStart = degrees_to_radians(angleStart - 90);
  const radiansEnd = degrees_to_radians(angleEnd - 90);

  this.ctx.save();
  this.ctx.beginPath();
  if (strokeColor) {
    this.ctx.strokeStyle = strokeColor;
    this.ctx.lineWidth = 2;
  }
  this.ctx.fillStyle = color;
  this.ctx.moveTo(cx, cy);
  this.ctx.arc(cx, cy, radius, radiansStart, radiansEnd);
  this.ctx.fill();
  if (strokeColor) {
    this.ctx.closePath();
    this.ctx.stroke();
  }
  this.ctx.restore();
};

CanvasWheel.prototype.drawText = function (x, y, angle, label, font, color, flip, textAlign) {
  const radians = degrees_to_radians(angle);

  this.ctx.save();
  this.ctx.textAlign = textAlign ?? 'center';
  this.ctx.textBaseline = 'middle';
  this.ctx.fillStyle = color ? color : '#fff';
  this.ctx.font = font ? font : 'bold 20px sans-serif';
  this.ctx.translate(x, y);
  this.ctx.rotate(radians);
  if (flip) {
    this.ctx.scale(-1, -1);
  }
  this.ctx.fillText(label, 0, 0);
  this.ctx.restore();
};

CanvasWheel.prototype.drawTextWithBlur = function (x, y, angle, label, font, color, blur, textAlign) {
  const radians = degrees_to_radians(angle);

  this.ctx.save();
  this.ctx.textAlign = textAlign ?? 'center';
  this.ctx.textBaseline = 'middle';
  this.ctx.fillStyle = color ? color : '#fff';
  this.ctx.font = font ? font : 'bold 20px sans-serif';
  this.ctx.translate(x, y);
  this.ctx.rotate(radians);
  this.ctx.shadowColor = color ? color : '#fff';
  this.ctx.shadowBlur = blur ? blur : 0;
  this.ctx.fillText(label, 0, 0);
  this.ctx.restore();
};

CanvasWheel.prototype.drawImage = function drawImage(img, x, y, width, height, angle) {
  return;
  /*
  const radians = degrees_to_radians(angle);

  const imgElement = new Image();
  imgElement.src = img;

  this.ctx.save();
  //Set the origin to the center of the image
  this.ctx.translate(x + width / 2, y + height / 2);
  //Rotate the canvas around the origin
  this.ctx.rotate(radians);
  //draw the image    
  this.ctx.drawImage(imgElement, width / 2 * (-1), height / 2 * (-1), width, height);
  this.ctx.restore();
  */
};

CanvasWheel.prototype.polarToCartesian = function (centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

CanvasWheel.prototype.drawProductsWheel = function (config, { cx, cy, radius }, initialSpin, disabledProducts = []) {
  if (config?.animation.disabled) return;

  cx = cx * this.pixelRatio;
  cy = cy * this.pixelRatio;
  radius = parseFloat(radius) * this.pixelRatio;

  const disabled = {};
  disabledProducts.forEach((type) => {
    disabled[type.toString()] = true;
  });

  let slot_count = config.slots.length;
  let commonAngle = config.commonAngle;

  let startAngle = getParsedValue(initialSpin, 0);
  startAngle = filter_degree(startAngle);

  for (let i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let t;
    let middleAngle = (startAngle + endAngle) / 2;
    let rotateAngle = startAngle + (endAngle - startAngle) / 2;

    let slotColor = slot.color;
    if (disabled[slot.type]) {
      slotColor = greyScaleColor(slotColor);
    }

    this.drawSector(cx, cy, radius, startAngle, endAngle, slotColor);

    if (slot.image) {
      t = this.polarToCartesian(cx, cy, radius - 20 * this.pixelRatio, middleAngle);
      this.drawImage(slot.image, t.x, t.y, slot.imageWidth, slot.imageHeight, rotateAngle);
    }

    if (slot.title) {
      t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop, 22) * this.pixelRatio, middleAngle);
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        slot.title,
        `bold ${getParsedValue(slot.titleFontSize, 18) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#fff'),
      );
    }

    if (slot.subtitle) {
      t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.subtitleTop, 42) * this.pixelRatio, middleAngle);
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        slot.subtitle,
        `bold ${getParsedValue(slot.subtitleFontSize, 10) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.subtitleColor, '#fff'),
      );
    }

    startAngle = endAngle;
  }
};

CanvasWheel.prototype.drawValuesWheel = function (config, { cx, cy, radius }, initialSpin, blink, slotId) {
  if (config?.animation.disabled) return;

  cx = cx * this.pixelRatio;
  cy = cy * this.pixelRatio;
  radius = parseFloat(radius) * this.pixelRatio;

  let slot_count = config.slots.length;
  let commonAngle = config.commonAngle;

  let startAngle = getParsedValue(initialSpin, 0);
  startAngle = filter_degree(startAngle);

  let maxId = '';
  let max = 0;

  for (let i = 0; i < slot_count; i++) {
    if (parseFloat(config.slots[i].value) > max) {
      max = parseFloat(config.slots[i].value);
      maxId = config.slots[i].id;
    }
  }

  let dSpecial = null;

  // console.log("valuesConfig", config);

  for (let i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let t;
    let middleAngle = (startAngle + endAngle) / 2;
    let rotateAngle = startAngle + (endAngle - startAngle) / 2;

    if (slot.id === maxId && !config.animation.noMax) {
      t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop, 20) * this.pixelRatio, middleAngle);

      let newRotateAngle = rotateAngle;
      if (slot.titleRotate) {
        newRotateAngle = rotateAngle + parseFloat(slot.titleRotate);
      }

      dSpecial = {
        id: slot.id,
        sector: { cx, cy, radius, startAngle, endAngle, middleAngle, rotateAngle, color: slot.color },
        textWithBlur: {
          x: t.x,
          y: t.y,
          rotateAngle: newRotateAngle,
          title: slot.title,
          font: `bold ${getParsedValue(slot.titleFontSize, 20) * this.pixelRatio}px ${FONT_FAMILY}`,
          color: getString(slot.titleColor, '#000'),
          blur: 3,
          textAlign: slot.titleAlign,
        },
        text: {
          x: t.x,
          y: t.y,
          rotateAngle: newRotateAngle,
          title: slot.title,
          font: `bold ${getParsedValue(slot.titleFontSize, 20) * this.pixelRatio}px ${FONT_FAMILY}`,
          color: getString(slot.titleColor, '#fff'),
          flip: false,
          textAlign: slot.titleAlign,
        },
        cfg: slot,
      };
      startAngle = endAngle;
      continue;
    }

    let slotColor = slot.color;
    if (blink && slot.id === slotId) {
      // do some color blinking
      slotColor = increaseBrightness(slotColor, this.blinkBrightness);
    }

    this.drawSector(cx, cy, radius, startAngle, endAngle, slotColor);

    t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop, 20) * this.pixelRatio, middleAngle);

    if (slot.titleRotate) {
      let newRotateAngle = rotateAngle + parseFloat(slot.titleRotate);

      this.drawTextWithBlur(
        t.x,
        t.y,
        newRotateAngle,
        slot.title,
        `bold ${getParsedValue(slot.titleFontSize, 20) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#000'),
        3,
        slot.titleAlign,
      );
      this.drawText(
        t.x,
        t.y,
        newRotateAngle,
        slot.title,
        `bold ${getParsedValue(slot.titleFontSize, 20) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#fff'),
        false,
        slot.titleAlign,
      );
    } else {
      this.drawTextWithBlur(
        t.x,
        t.y,
        rotateAngle,
        slot.title,
        `bold ${getParsedValue(slot.titleFontSize, 20) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#000'),
        3,
        slot.titleAlign,
      );
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        slot.title,
        `bold ${getParsedValue(slot.titleFontSize, 20) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#fff'),
        false,
        slot.titleAlign,
      );
    }

    if (typeof slot.title1 === 'string' && slot.title1.length > 0) {
      t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop1, 33) * this.pixelRatio, middleAngle);
      this.drawTextWithBlur(
        t.x,
        t.y,
        rotateAngle,
        slot.title1,
        `bold ${getParsedValue(slot.titleFontSize1, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        '#000',
        3,
      );
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        slot.title1,
        `bold ${getParsedValue(slot.titleFontSize1, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor1, '#fff'),
      );
    }
    if (typeof slot.title2 === 'string' && slot.title2.length > 0) {
      t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop2, 43) * this.pixelRatio, middleAngle);
      this.drawTextWithBlur(
        t.x,
        t.y,
        rotateAngle,
        slot.title2,
        `bold ${getParsedValue(slot.titleFontSize2, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        '#000',
        3,
      );
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        slot.title2,
        `bold ${getParsedValue(slot.titleFontSize2, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor2, '#fff'),
      );
    }
    if (typeof slot.title3 === 'string' && slot.title3.length > 0) {
      t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop3, 53) * this.pixelRatio, middleAngle);
      this.drawTextWithBlur(
        t.x,
        t.y,
        rotateAngle,
        slot.title3,
        `bold ${getParsedValue(slot.titleFontSize3, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        '#000',
        3,
      );
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        slot.title3,
        `bold ${getParsedValue(slot.titleFontSize3, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor3, '#fff'),
      );
    }

    startAngle = endAngle;
  }

  let t = null;

  if (dSpecial) {
    let slotColor = dSpecial.sector.color;
    if (blink && dSpecial.id === slotId) {
      // do some color blinking
      slotColor = increaseBrightness(slotColor, this.blinkBrightness);
    }

    this.drawSector(
      dSpecial.sector.cx,
      dSpecial.sector.cy,
      dSpecial.sector.radius,
      dSpecial.sector.startAngle,
      dSpecial.sector.endAngle,
      slotColor,
      '#fff',
    );
    this.drawTextWithBlur(
      dSpecial.textWithBlur.x,
      dSpecial.textWithBlur.y,
      dSpecial.textWithBlur.rotateAngle,
      dSpecial.textWithBlur.title,
      dSpecial.textWithBlur.font,
      dSpecial.textWithBlur.color,
      dSpecial.textWithBlur.blur,
      dSpecial.textWithBlur.textAlign,
    );
    this.drawText(
      dSpecial.text.x,
      dSpecial.text.y,
      dSpecial.text.rotateAngle,
      dSpecial.text.title,
      dSpecial.text.font,
      dSpecial.text.color,
      dSpecial.text.flip,
      dSpecial.text.textAlign,
    );

    if (dSpecial.cfg && typeof dSpecial.cfg.title1 === 'string' && dSpecial.cfg.title1.length > 0) {
      t = this.polarToCartesian(
        dSpecial.sector.cx,
        dSpecial.sector.cy,
        radius - getParsedValue(dSpecial.cfg.titleTop1, 33) * this.pixelRatio,
        dSpecial.sector.middleAngle,
      );
      this.drawTextWithBlur(
        t.x,
        t.y,
        dSpecial.sector.rotateAngle,
        dSpecial.cfg.title1,
        `bold ${getParsedValue(dSpecial.cfg.titleFontSize1, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        '#000',
        3,
      );
      this.drawText(
        t.x,
        t.y,
        dSpecial.sector.rotateAngle,
        dSpecial.cfg.title1,
        `bold ${getParsedValue(dSpecial.cfg.titleFontSize1, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(dSpecial.cfg.titleColor1, '#fff'),
      );
    }
    if (dSpecial.cfg && typeof dSpecial.cfg.title2 === 'string' && dSpecial.cfg.title2.length > 0) {
      t = this.polarToCartesian(
        dSpecial.sector.cx,
        dSpecial.sector.cy,
        radius - getParsedValue(dSpecial.cfg.titleTop2, 43) * this.pixelRatio,
        dSpecial.sector.middleAngle,
      );
      this.drawTextWithBlur(
        t.x,
        t.y,
        dSpecial.sector.rotateAngle,
        dSpecial.cfg.title2,
        `bold ${getParsedValue(dSpecial.cfg.titleFontSize2, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        '#000',
        3,
      );
      this.drawText(
        t.x,
        t.y,
        dSpecial.sector.rotateAngle,
        dSpecial.cfg.title2,
        `bold ${getParsedValue(dSpecial.cfg.titleFontSize2, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(dSpecial.cfg.titleColor2, '#fff'),
      );
    }
    if (dSpecial.cfg && typeof dSpecial.cfg.title3 === 'string' && dSpecial.cfg.title3.length > 0) {
      t = this.polarToCartesian(
        dSpecial.sector.cx,
        dSpecial.sector.cy,
        radius - getParsedValue(dSpecial.cfg.titleTop3, 53) * this.pixelRatio,
        dSpecial.sector.middleAngle,
      );
      this.drawTextWithBlur(
        t.x,
        t.y,
        dSpecial.sector.rotateAngle,
        dSpecial.cfg.title3,
        `bold ${getParsedValue(dSpecial.cfg.titleFontSize3, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        '#000',
        3,
      );
      this.drawText(
        t.x,
        t.y,
        dSpecial.sector.rotateAngle,
        dSpecial.cfg.title3,
        `bold ${getParsedValue(dSpecial.cfg.titleFontSize3, 11) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(dSpecial.cfg.titleColor3, '#fff'),
      );
    }
  }
};

CanvasWheel.prototype.drawMultipliersWheel = function (
  config,
  { cx, cy, radius },
  initialSpin,
  type,
  blink,
  winSlot,
  prize,
) {
  if (config?.animation.disabled) return;

  cx = cx * this.pixelRatio;
  cy = cy * this.pixelRatio;
  radius = parseFloat(radius) * this.pixelRatio;

  let slot_count = config.slots.length;
  let commonAngle = config.commonAngle;

  let startAngle = getParsedValue(initialSpin, 0);
  startAngle = filter_degree(startAngle);

  for (let i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let t;
    let middleAngle = (startAngle + endAngle) / 2;
    let rotateAngle = startAngle + (endAngle - startAngle) / 2;

    let slotColor = type === 'free' ? greyScaleColor(slot.color) : slot.color;

    if (winSlot && blink && (slot.id === winSlot.id || (winSlot.second && winSlot.second.id === slot.id))) {
      // do some color blinking
      slotColor = increaseBrightness(slotColor, this.blinkBrightness);

      if (type === 'free') {
        slotColor = greyScaleColor(slotColor);
      }
    }

    this.drawSector(cx, cy, radius, startAngle, endAngle, slotColor);

    t = this.polarToCartesian(cx, cy, radius - getParsedValue(slot.titleTop, 26) * this.pixelRatio, middleAngle);

    let title = slot.title;
    if (prize && prize.type === 2) {
      title = `x${parseFloat(slot.value) + 1}`;
    }

    if (slot.titleRotate) {
      let newRotateAngle = rotateAngle + parseFloat(slot.titleRotate);

      this.drawText(
        t.x,
        t.y,
        newRotateAngle,
        title,
        `bold ${getParsedValue(slot.titleFontSize, 25) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#fff'),
        true,
        slot.titleAlign,
      );
    } else {
      this.drawText(
        t.x,
        t.y,
        rotateAngle,
        title,
        `bold ${getParsedValue(slot.titleFontSize, 25) * this.pixelRatio}px ${FONT_FAMILY}`,
        getString(slot.titleColor, '#fff'),
        true,
        slot.titleAlign,
      );
    }

    startAngle = endAngle;
  }
};

CanvasWheel.prototype.drawDisabledOverlay = function ({ cx, cy, radius }) {
  cx = cx * this.pixelRatio;
  cy = cy * this.pixelRatio;
  radius = radius * this.pixelRatio;

  this.ctx.createLinearGradient(cx, cy, cx, cy * 2);

  this.ctx.save();
  var gradient = this.ctx.createLinearGradient(cx, cy - radius, cx, cy + radius * 2);

  // Add three color stops
  gradient.addColorStop(0, 'rgba(10, 15, 141,1.0)');
  gradient.addColorStop(0.439, 'rgba(14, 20, 155, .6)');
  gradient.addColorStop(1, 'rgba(21, 31, 184, 0.0)');

  this.ctx.fillStyle = gradient;
  //this.ctx.fillRect(cx - radius, cy - radius, radius * 2, radius * 2);
  this.ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
  this.ctx.fill();
  this.ctx.restore();
};

export default CanvasWheel;
