import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { fetchCampaign, fetchCollected, collectItem, clearCollected } from '@/store/slices/airdrop';
import { setMarketingAgreements } from '@/modules/casino/store/actions';
import useCountRandom from './lib/useCountRandom';
import { duration } from 'moment';
import { delay } from 'lodash';

type AirdropProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

type ModuleStateProps = {
  isOpen: boolean;
  currentStep: string;
  hasErrors: boolean;
  error: string;
  isLoading: boolean;
  itemName: string | null | undefined;
  topMargin: string;
  bottomMargin: string;
  inView: boolean;
  beginCount: boolean;
  skip: boolean;
  campaignId: string;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Airdrops = (componentProps: AirdropProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();
  const campaign = useAppSelector((state) => state.airdrop.campaign);
  const collectedItems = useAppSelector((state) => state.airdrop.collectedItems);
  const authentication = useAppSelector((state) => state.authentication);
  const marketingAgreements = useAppSelector((state) => state.alerts.settings.marketing_agreements);
  const navigate = useNavigate();
  const topOffset =
    props.properties?.topOffset && !isNaN(parseFloat(props.properties?.topOffset))
      ? parseFloat(props.properties?.topOffset)
      : 0;
  const mode =
    props.properties?.mode && (props.properties?.mode === 'header' || props.properties?.mode === 'widget')
      ? props.properties?.mode
      : 'widget';

  const initialState = {
    isOpen: false,
    currentStep: 'step1',
    hasErrors: false,
    error: '',
    isLoading: false,
    itemName: '',
    inView: true,
    topMargin: 'in',
    bottomMargin: 'in',
    beginCount: false,
    skip: false,
    campaignId: '',
  };

  const [state, setState] = React.useState<ModuleStateProps>({ ...initialState });

  const progress = useCountRandom({
    begin: state.beginCount,
    skip: state.skip,
    duration: 3000,
    delay: 0,
  });

  React.useEffect(() => {
    dispatch(fetchCampaign());
  }, []);

  React.useEffect(() => {
    if (campaign?.campaign_id && state.campaignId !== campaign?.campaign_id) {
      dispatch(clearCollected());
      setState({ ...initialState, campaignId: campaign?.campaign_id });
    }
  }, [campaign, state]);

  React.useEffect(() => {
    const classes: string[] = [];
    classes.push(`airdrops-top-margin-${state.topMargin ?? 'in'}`);
    classes.push(`airdrops-bottom-margin-${state.bottomMargin ?? 'in'}`);
    document.body.classList.add(...classes);

    return () => {
      document.body.classList.remove(...classes);
    };
  }, [state]);

  React.useEffect(() => {
    if (collectedItems) {
      let ids = Object.keys(collectedItems) as string[];
      ids = ids.filter((id: string) => !collectedItems[id]?.loaded);
      if (ids.length > 0) {
        ids = ids.map((id) => collectedItems[id]?.collect?.collect_id).filter((id) => id != null) as string[];
        dispatch(fetchCollected({ ids: ids }));
      }
    }
  }, [collectedItems]);

  const onVisibilityChange = React.useCallback((margin: string, value: string, inView: boolean) => {
    const data: any = { inView: inView };
    if (margin === 'topMargin' || margin === 'bottomMargin') {
      if (value === 'in' || value === 'above' || value === 'below') {
        data[margin] = value;
      }
    }

    setState((v) => ({ ...v, ...data }));
  }, []);

  const onCollectItem = (e: React.FormEvent<HTMLInputElement>) => {
    if (!(authentication && authentication.auth_type === 'user')) {
      localStorage.setItem('redirect', location.pathname);
      navigate('/login');
      return;
    }

    if (e.currentTarget.dataset.id != null) {
      console.log('Collecting item:', {
        // e,
        itemId: e.currentTarget.dataset.id,
        itemName: e.currentTarget.dataset.name,
      });

      const item: any = campaign?.items.find((item) => item.item_id === e.currentTarget.dataset.id) ?? {};

      setState((v) => ({
        ...v,
        isOpen: true,
        itemName: e.currentTarget.dataset.name,
        currentStep: 'step2',
        beginCount: true,
        skip: item.item_value_min && item.item_value_max && item.item_value_min == item.item_value_max,
      }));
      dispatch(collectItem({ id: e.currentTarget.dataset.id }));
    }
  };

  React.useEffect(() => {
    if (campaign && collectedItems && state.currentStep !== 'step3') {
      if (collectedItems[campaign.campaign_id]?.loaded && state.currentStep === 'step2' && progress === 1) {
        setState((v) => ({
          ...v,
          currentStep: 'step3',
          beginCount: false,
        }));
      } else if (collectedItems[campaign.campaign_id]?.loaded && state.currentStep === 'step1') {
        setState((v) => ({ ...v, currentStep: 'step3', beginCount: false }));
      }
    }
  }, [progress, collectedItems, campaign, state.currentStep]);

  const onExpired = () => {
    setState((v) => {
      return { ...v, currentStep: 'step4' };
    });
  };

  const onShowCollected = React.useCallback(() => {
    setState((v) => ({
      ...v,
      isOpen: true,
    }));
  }, []);
  const onHideCollected = React.useCallback(() => {
    setState((v) => ({
      ...v,
      isOpen: false,
    }));
  }, []);

  const onToggleAgreement = React.useCallback(() => {
    dispatch(
      setMarketingAgreements({
        all: true,
        value: 1,
      }),
    );
  }, [marketingAgreements]);

  const contextValue = React.useMemo(() => {
    if (!campaign) return {};

    let campaignType = 'INDIVIDUAL_ITEM';
    const items = [];

    const collected = collectedItems?.[campaign.campaign_id]?.item ?? null;

    if (Array.isArray(campaign.items)) {
      campaign.items.find((item) => {
        if (item.item_display_type === 'STACK') {
          campaignType = 'STACK';
          return true;
        }
        return false;
      });

      if (campaignType === 'INDIVIDUAL_ITEM') {
        // create an array of 3 identical items
        items.push(campaign.items[0]);
        items.push(campaign.items[0]);
        if (!collected) {
          items.push(campaign.items[0]);
        }
      }
    }

    const endDateStr = campaign.campaign_end_date; // '2024-06-14T06:47:00.000Z';
    const endDate = new Date(endDateStr).getTime();

    const hasMarketingAggreement =
      marketingAgreements?.marketing_receive_sms === 1 ||
      marketingAgreements?.marketing_receive_email === 1 ||
      marketingAgreements?.marketing_receive_phone === 1 ||
      marketingAgreements?.marketing_partners === 1;

    let collectedValue = parseFloat(collected?.item_value ?? '0');

    if (isNaN(collectedValue)) collectedValue = 0;
    const progressValue = collectedValue / (progress && state.beginCount ? progress : 1);

    return {
      campaign: campaign,
      artBundle: campaign.campaign_art_bundle ?? null,
      title: campaign.campaign_name,
      startDate: new Date(campaign.campaign_start_date).getTime(),
      endDate: endDate,
      items: campaignType === 'INDIVIDUAL_ITEM' ? items : campaign.items,
      onExpired,
      onCollectItem,
      currentStep: state.currentStep,
      hasErrors: state.hasErrors,
      error: state.error,
      isLoading: state.isLoading,
      isOpen: state.isOpen,
      collected: collected,
      itemName: state.itemName,
      remaining: (campaign.total_available ?? 0) - (campaign.total_collected ?? 0),
      campaignType: campaignType,
      hasMarketingAggreement: hasMarketingAggreement,
      onToggleAgreement: onToggleAgreement,
      onVisibilityChange: onVisibilityChange,
      inView: state.inView,
      topOffset: topOffset,
      mode: mode,
      onShowCollected,
      onHideCollected,
      campaignValue: campaign?.campaign_value ?? '',
      campaignCurrency: campaign?.campaign_currency ?? '',
      randomProgress: (progressValue * 100) / parseFloat(collected?.item_value_max ?? '0'),
      randomValue: Math.floor(progressValue),
    };
  }, [
    componentProps,
    campaign,
    state,
    collectedItems,
    marketingAgreements,
    onVisibilityChange,
    topOffset,
    mode,
    onShowCollected,
    onHideCollected,
    progress,
  ]);

  if (!campaign) return null;

  console.log('Airdrops[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Airdrops;
