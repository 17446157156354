import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { subscribe, unsubscribe } from '@/modules/live-casino/store/actions/app';
import PragmaticLive from '@/store/sagas/pragmaticLiveWS';

//import './index.scss';

type LiveStatsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const localToRemoteId: { [id: string]: string } = {
  '1904': '303',
  '1905': '401',
  '1906': '204',
  '2006': '402',
  '2007': '225',
  '2008': '203',
  '2009': '201',
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const colors: { [key: string]: string } = {
  // generate all roulette colors by number
  '0': 'green',
  '1': 'red',
  '2': 'black',
  '3': 'red',
  '4': 'black',
  '5': 'red',
  '6': 'black',
  '7': 'red',
  '8': 'black',
  '9': 'red',
  '10': 'black',
  '11': 'black',
  '12': 'red',
  '13': 'black',
  '14': 'red',
  '15': 'black',
  '16': 'red',
  '17': 'black',
  '18': 'red',
  '19': 'red',
  '20': 'black',
  '21': 'red',
  '22': 'black',
  '23': 'red',
  '24': 'black',
  '25': 'red',
  '26': 'black',
  '27': 'red',
  '28': 'black',
  '29': 'black',
  '30': 'red',
  '31': 'black',
  '32': 'red',
  '33': 'black',
  '34': 'red',
  '35': 'black',
  '36': 'red',
};

const buildStats = (stats: any, providerId: string, currency: string) => {
  if (providerId === '7') {
    return {
      seatedPlayers: stats?.totalSeatedPlayers,
      maxPlayers: stats?.tableLimits?.maxPlayers ?? 0,
      tableType: stats?.tableType ?? '',
      dealer: stats?.dealer?.name ?? '',
      currency: stats?.currency,
      minBet: stats?.tableLimits?.minBet,
      maxBet: stats?.tableLimits?.maxBet,
      lastRouletteResults:
        stats?.tableType === 'ROULETTE' && stats?.last20Results
          ? stats?.last20Results.map((e: any) => ({ result: e.result, color: e.color }))
          : [],
      image: stats?.tableImage ?? '',
    };
  } else if (providerId === '8') {
    return {
      seatedPlayers: stats?.players,
      maxPlayers: stats?.tableLimits?.maxPlayers ?? 0,
      tableType: stats?.gameType?.toUpperCase() ?? '',
      dealer: stats?.dealer?.name ?? '',
      currency: currency,
      minBet: stats?.betLimits?.[currency.toUpperCase()]?.min,
      maxBet: stats?.betLimits?.[currency.toUpperCase()]?.max,
      lastRouletteResults:
        stats?.gameType === 'Roulette' && stats?.results
          ? stats?.results.map((e: any) => ({ result: e, color: colors[e] }))
          : [],
      image: stats?.videoSnapshot?.thumbnails?.M ?? '',
    };
  }
};

const LiveStats = (componentProps: LiveStatsProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const provider_id = dataElementContext?.provider_id;
  const id = dataElementContext?.id;
  const dispatch = useAppDispatch();
  const tables = useAppSelector((state) => state.pragmaticLive.tables);
  const evoTables: any = useAppSelector((state) => state.liveCasino.tables?.state?.tables);

  React.useEffect(() => {
    if (provider_id === '7' && id && localToRemoteId[id]) {
      PragmaticLive.subscribe(localToRemoteId[id], 'RON');
    }

    if (provider_id === '8' && id) {
      dispatch(subscribe());
    }

    return () => {
      if (provider_id === '7' && id && localToRemoteId[id]) {
        PragmaticLive.unsubscribe(localToRemoteId[id]);
      }
      if (provider_id === '8' && id) {
        dispatch(unsubscribe());
      }
    };
  }, [provider_id, id]);

  const contextValue = React.useMemo(() => {
    let stats: any = {};
    if (provider_id === '7' && id && localToRemoteId[id] && tables[localToRemoteId[id]]) {
      stats = buildStats(tables[localToRemoteId[id]], provider_id, window.config.defaultCurrency);
    } else if (provider_id === '8' && id) {
      const [gameType, tableId] = id.split('/');
      if (evoTables?.[tableId]) {
        stats = buildStats(evoTables?.[tableId], provider_id, window.config.defaultCurrency);
      }
    }

    /*
      tableType: "ROULETTE"
      tableType: "BACCARAT"
      tableType: "BLACKJACK"
    */

    return {
      ...dataElementContext,
      stats: stats,
    };
  }, [dataElementContext, componentProps, provider_id, id, tables, evoTables]);

  //console.log('contextValue', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LiveStats;
