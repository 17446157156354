import React from 'react';
import styled from 'styled-components';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import moment from 'moment';

import { MessageRepository, MessageContentType } from '@amityco/ts-sdk';
import { useAppSelector, useAppDispatch } from '@/store';
import { ChannelMember, SocialCapability, SocialHubUser } from '..';
import { fetchCampaign } from '@/store/slices/airdrop';

import './index.scss';
import LOG from '../utils/Log';

const AMITY_CHANNEL_ID = window.config.AMITY_MAIN_CHANNEL_ID;

type ChatProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Chat = (componentProps: ChatProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  //# CONTEXT
  const dataElementContext = React.useContext(DataElementContext);
  const currentUser: SocialHubUser = dataElementContext.currentUser;
  // const members: Amity.LiveCollection<Amity.Membership<'channel'>> = dataElementContext.channelMembers;
  const members: ChannelMember[] = dataElementContext.channelMembers;
  const messages: Amity.LiveCollection<Amity.Message> = dataElementContext.channelMessages;

  // App store
  const campaign = useAppSelector((state) => state.airdrop.campaign);
  const dispatch = useAppDispatch();

  const sendMessage = React.useCallback((messageText: string) => {
    const message = {
      subChannelId: AMITY_CHANNEL_ID,
      dataType: MessageContentType.TEXT,
      data: {
        text: messageText,
      },
    };

    shouldScrollRef.current = true;

    MessageRepository.createMessage(message).then((message) => {
      LOG.ok('SENT MESSAGE', message);
    });
  }, []);

  //--- AUTOSCROLL ---
  const [messagesContainer, setMessagesContainer] = React.useState<HTMLDivElement>();
  const shouldScrollRef = React.useRef(true);

  const handleScroll = React.useCallback(() => {
    if (messagesContainer) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainer;
      const atBottom = scrollHeight - scrollTop === clientHeight;
      shouldScrollRef.current = atBottom;
    }
  }, [messagesContainer]);

  React.useEffect(() => {
    dispatch(fetchCampaign());
  }, []);

  React.useEffect(() => {
    if (messages?.data && members.length > 0 && !messages.loading && messagesContainer && shouldScrollRef.current) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [members, messages?.data, messages?.loading, messagesContainer]);

  //!
  React.useEffect(() => {
    const element = document.querySelector('.chat-section') as HTMLDivElement;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      setMessagesContainer(element);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const formatDuration = React.useCallback((timestamp: string) => {
    const now = moment();
    const createdAt = moment(timestamp);
    const isToday = now.isSame(createdAt, 'day');
    const isYesterday = now.clone().subtract(1, 'days').isSame(createdAt, 'day');

    if (isToday) {
      return createdAt.format('HH:mm');
    } else if (isYesterday) {
      return `Yesterday, ${createdAt.format('HH:mm')}`;
    } else {
      return createdAt.format('DD.MM HH:mm');
    }
  }, []);

  //! FORCED HTML TAG REMOVAL
  function encodeTags(str: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  }

  //# --------------------------------------------------------------------------
  const contextValue = React.useMemo(() => {
    const listMessages: any[] =
      messages?.data
        //@ts-ignore
        .filter((message) => message.syncState !== 'error')
        .map((message) => {
          //console.log('SocialHubChat[message]', message);
          return {
            messageId: message.messageId,
            creatorId: message.creatorId,
            mine: message.creatorId === currentUser?.userId,
            memberDisplayName: members?.find((m) => m.userId === message.creatorId)?.displayName,
            memberAvatarUrl: members?.find((m) => m.userId === message.creatorId)?.avatarUrl,
            isMod: members
              ?.find((m) => m.userId === message.creatorId)
              ?.roles.some((role) => role.includes('moderator') || role.includes('admin')),
            dataType: message.dataType,
            //@ts-ignore
            text: encodeTags(message.data?.text ?? ''),
            createdAtFull: message.createdAt,
            createdAt: formatDuration(message.createdAt),
            metadata: message.metadata,
          };
        })
        .reverse() ?? [];

    if (
      currentUser.isConnected &&
      listMessages.length &&
      campaign?.campaign_id &&
      campaign?.campaign_status === 'active'
    ) {
      const message = {
        createdAt: formatDuration(campaign.campaign_start_date),
        createdAtFull: campaign.campaign_start_date,
        creatorId: 'airdrop_system',
        dataType: 'custom',
        isMod: false,
        memberAvatarUrl: undefined,
        memberDisplayName: 'Airdrop',
        messageId: campaign.campaign_id,
        mine: false,
        text: '',
        metadata: {
          type: 'airdrop_widget',
          id: campaign?.campaign_id,
        },
      };

      listMessages.push(message);
      listMessages.sort((a, b) => {
        return new Date(a.createdAtFull).getTime() - new Date(b.createdAtFull).getTime();
      });
    }

    return {
      isConnected: currentUser.isConnected,
      isGuest: currentUser.isGuest,
      isMuted: members?.find((member) => member.userId === currentUser?.userId)?.isMuted ?? false,
      canMessage: currentUser.capabilities.includes(SocialCapability.CHAT_CAN_MESSAGE),
      messagesLoading: messages?.loading ?? false,
      membersLoading: members.length === 0,
      handleScroll,
      isScrollAtBottom: shouldScrollRef.current,
      sendMessage,
      messages: listMessages,
    };
  }, [
    currentUser.isConnected,
    currentUser.isGuest,
    currentUser.userId,
    currentUser.capabilities,
    members,
    messages?.loading,
    messages?.data,
    handleScroll,
    sendMessage,
    campaign,
    formatDuration,
  ]);
  //console.log('SocialHubChat[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Chat;
