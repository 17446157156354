import {
  Card,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import Utils from '@/modules/casino/utils/Utils';
import PaymentProvider, {
  PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import {
  bridgerPayDepositResponse,
  fetchPlayerPaymentMethods,
  finishBridgerPayDeposit,
  initiateBridgerPayDeposit,
} from '@/modules/casino/store/actions/deposit';
const RETRIES = 50;

class Bridger extends PaymentProvider implements PaymentProviderInterface {
  private applePay: undefined;
  private cardsFetched: boolean = false;
  private cashierToken: string | boolean | undefined;
  private bloxController: any;
  private bloxSettings: any = {};
  private bridgerCardInfo:
    | {
        token: string;
        encryptedCVV: string;
      }
    | undefined;
  private inputTimeout: any;
  private retryNo: number = RETRIES;
  private authInProgress: boolean = false;
  private validateCardInfo: any = false;

  constructor(data: PaymentProviderConstructor) {
    super(data);
    try {
      this.setType(PaymentProviderConstant.bridger);
      // unescape the string
      this.bloxSettings = JSON.parse(data.settings.blox);
      if (data.settings.cards) {
        this.setCards(data.settings.cards);
      }
      if (data.settings.validateCardInfo) {
        this.validateCardInfo = data.settings.validateCardInfo;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  injectScript(): void {
    setTimeout(() => {
      Utils.injectScript('https://ng-checkout.bridgerpay.com/sdk', 'bridger-sdk', this.bridgerScriptInserted, {
        async: true,
      });
    }, 3500);
  }

  init(data: any): void {
    super.init(data);
    this.injectScript();

    this.cashierToken = data.cashierToken ?? false;
    if (this.cashierToken) {
      this.authInProgress = false;
    }
    this.cardsFetched = data.cardsFetched ?? false;
    console.log('cardsFetched', this.cardsFetched, this.getCards());
    if (this.cardsFetched) {
    }
    window.addEventListener('message', this.processBridgerPayMessages, false);
  }

  destroy(): void {
    this.dispatch(bridgerPayDepositResponse({ reset: true }));
    this.bloxController?.destroy();
    window.removeEventListener('message', this.processBridgerPayMessages, false);
    clearTimeout(this.inputTimeout);
  }
  processBridgerPayMessages = (e: any) => {
    if (e.data.event == '[bp]:fingerprint') {
      const DDC = e.data.fingerprint;
      this.dispatch(
        finishBridgerPayDeposit({
          amount: this.paymentDetails.amount,
          bonusId: this.paymentDetails.bonusId,
          card: {
            token: this.bridgerCardInfo?.token,
            encryptedCVV: this.bridgerCardInfo?.encryptedCVV,
          },
          fingerprint: DDC,
        }),
      );
    }
  };
  confirmDepositValue() {
    this.auth();
    this.fetchCards();
  }

  auth() {
    if (!this.isAuthenticated() && !this.authInProgress) {
      this.authInProgress = true;
      this.dispatch(initiateBridgerPayDeposit({})); // auth token
    }
  }
  fetchCards() {
    if (!this.cardsFetched) {
      this.dispatch(fetchPlayerPaymentMethods());
    }
  }

  setCards(cards: any) {
    // check if cards is of type Cards
    let foundDefault = false;
    const cardsInitialized = cards.length > 0 && cards[0].cardHolderName === undefined;
    const cardsNotInitialized = cards.length > 0 && cards[0].cardHolderName !== undefined;
    const cardsList: Card[] = cardsInitialized
      ? cards
      : cards.map((card: any): Card | undefined => {
          const c = this.createCard(card);

          if (card.is_default) {
            c.selected = true;
            foundDefault = true;
            this.setPaymentDetails({
              ...this.paymentDetails,
              card: c,
            });
          }

          return c;
        });
    if (!foundDefault && cardsNotInitialized) {
      cardsList[0].selected = true;
      this.setPaymentDetails({
        ...this.paymentDetails,
        card: cardsList[0],
      });
    }
    super.setCards(cardsList);
  }

  setPaymentDetails(paymentDetails: any, setPaymentDetailsCallBack?: () => void): any {
    return super.setPaymentDetails(paymentDetails ?? null, setPaymentDetailsCallBack);
  }

  setBonusId(bonusId: number | null): void {
    super.setBonusId(bonusId);
  }

  createCard(card: any): Card {
    const c = super.createCard({
      card_token: card.token,
      card_type_id: card.cardBrand,
      card_number: card.cardNumber,
      expiration_date: card.expirationDate,
      card_full_name: card.cardHolderName,
      bin: card.cardBin,
    });

    let cardNumber = c.number;
    // format cardNumber to be displayed as **** **** **** 1234
    const cardNumberRaw = cardNumber?.replace(/\s/g, '');
    if (cardNumberRaw?.length === 16) {
      // replace first chars with *
      cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
    }

    switch (c.type) {
      case 'visa':
        c.type = 'Visa';
        break;
      case 'mastercard':
        c.type = 'MasterCard';
        break;
      case 'maestro':
        c.type = 'Maestro';
        break;
      default:
      // do nothing
    }

    return { ...c, number: cardNumber };
  }

  bridgerScriptInserted = () => {};

  deposit(): void {
    this.showLoader();
    // payment with new card
    if (this.bloxController.isAllValid === false) {
      this.bloxController.validateAll();
      return;
    }

    if (this.paymentDetails?.cardInfo?.holderName) {
      this.bloxController
        .createPaymentCardToken({
          // @ts-ignore
          cardHolderName: this.paymentDetails.cardInfo?.holderName, // This field is mandatory
          singleUse: false,
        })
        .then((response: any) => {
          // {
          //     "type": "multiUse",
          //     "token": "f1678395-bf54-497f-9c41-98cd4f267379",
          //     "card": {
          //         "maskedNumber": "424242******4242",
          //         "bin": "42424242",
          //         "last4Digits": "4242",
          //         "expireMonth": 3,
          //         "expireYear": 2028,
          //         "holderName": "liviu barbacaru",
          //         "brand": "visa",
          //         "type": "credit",
          //         "issuer": "stripe payments uk limited",
          //         "country": "GB"
          //     },
          //     "encryptedCvv": "CiUAvjDkBXgBGE2I1D20AE9bfOirs/H7jJSio/+gH597WTkJTV3PEkcqRQoUCgwyWAMG5VqFsgsDuewQxbPGtAkSEwoLqw9VGQ6Z7HyDSc0Q2dOmiAoaGAoQUstTPGldwl9C5lFAhO889BClia74Dw==",
          //     "maskedEmail": "l*************u@g***l.com",
          //     "status": "active",
          //     "created": 1723042802
          // }
          this.bridgerCardInfo = {
            token: response.token,
            encryptedCVV: response.encryptedCvv,
          };
          this.dispatch(
            finishBridgerPayDeposit({
              card: {
                bin: response.card.bin,
              },
            }),
          );
        });
    } else {
      // payment with saved card
      this.bloxController.encryptCvv().then((response: any) => {
        const pd = this.getPaymentDetails();
        if (pd.card) {
          this.bridgerCardInfo = {
            token: pd.card.token,
            encryptedCVV: response.encryptedCvv,
          };
          this.dispatch(
            finishBridgerPayDeposit({
              card: {
                bin: pd.card.bin,
              },
            }),
          );
        }
      });
    }
  }

  fingerprint() {}

  confirmPayment(): void {
    try {
      this.deposit();
    } catch (e) {
      console.log(e);
    }
  }

  sendConfirmation(info: any) {
    const axios = ClientAPI.getInstance();
    const pac = PlayerAbuseChecker.getInfo();
    const data = {
      chargeToken: info.data.chargeToken,
      amount: info.amount,
      bonusId: info.bonusId,
      ...pac,
    };

    axios({
      url: '/api/pay-checkout/charge-token',
      method: 'post',
      data: data,
    })
      .then((response) => {
        // @ts-ignore
        if (response?.result?.ResponseCode) {
          this.triggerError();
        } else {
          // @ts-ignore
          if (response?.status === 'OK') {
            setTimeout(this.triggerSuccess, 500);
          } else {
            this.triggerError();
          }
        }
      })
      .catch(() => {
        this.triggerError();
      });
  }

  getBloxSettings() {
    return {
      token: this.cashierToken,
      ...this.bloxSettings,
    };
  }

  initInputsForNewCard() {
    const elementsExists =
      document.getElementById('bridger-card-number') &&
      document.getElementById('bridger-card-exp') &&
      document.getElementById('bridger-card-cvv');

    // @ts-ignore
    if (typeof window.BP === 'undefined' || !this.cashierToken || !elementsExists) {
      if (this.retryNo > 0) {
        clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(
          () => {
            this.initInputsForNewCard();
          },
          300,
          this,
        );
        this.retryNo--;
      } else {
        this.retryNo = RETRIES;
      }
      return;
    }
    if (this.cashierToken) {
      super.initInputsForNewCard();

      this.bloxController?.destroy();

      // @ts-ignore
      this.bloxController = new window.BP.BloxController(this.getBloxSettings());

      const cardNumber = this.bloxController.createBlox('cardNumber', {
        placeholder: '0000 0000 0000 0000',
      });
      cardNumber.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('number', e) : null));
      cardNumber.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('number', e) : null));
      cardNumber.mount('#bridger-card-number .eko-bridger-input');

      const cardExpiryDate = this.bloxController.createBlox('cardExpiryDate', {
        placeholder: 'MM / YY',
      });
      cardExpiryDate.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('expiry', e) : null));
      cardExpiryDate.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('expiry', e) : null));
      cardExpiryDate.mount('#bridger-card-exp .eko-bridger-input');

      const cardCvv = this.bloxController.createBlox('cardCvv', {
        placeholder: '---',
        unmaskedCVV: false,
      });
      cardCvv.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.mount('#bridger-card-cvv .eko-bridger-input');
    }
  }
  initInputsForSavedCards() {
    const elementsExists = document.getElementById('bridger-card-cvv');

    // @ts-ignore
    if (typeof window.BP === 'undefined' || !this.cashierToken || !elementsExists) {
      if (!this.cashierToken) {
        this.auth();
      }
      if (this.retryNo > 0) {
        clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(
          () => {
            this.initInputsForSavedCards();
          },
          300,
          this,
        );
        this.retryNo--;
      } else {
        this.retryNo = RETRIES;
      }
      return;
    }
    if (this.cashierToken) {
      super.initInputsForSavedCards();
      this.bloxController?.destroy();

      // @ts-ignore
      this.bloxController = new window.BP.BloxController(this.getBloxSettings());

      const cardCvv = this.bloxController.createBlox('cardCvv', {
        placeholder: '---',
        unmaskedCVV: false,
      });
      cardCvv.on('change', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.on('validate', (e: any) => (this.validateCardInfo ? this.validateCardInfo('cvv', e) : null));
      cardCvv.mount('#bridger-card-cvv .eko-bridger-input');
    }
  }
  quickDeposit(data: any) {
    this.setPaymentDetails({
      amount: data.amount,
      bonusId: data.bonusId,
      card: data.card,
    });
    if (!data.loading) {
      this.auth();
    }

    // retry mechanism
    if (this.isAuthenticated()) {
      this.confirmPayment();
    } else {
      setTimeout(() => {
        this.quickDeposit({ ...data, loading: true });
      }, 300);
    }
  }

  isAuthenticated() {
    return !!this.cashierToken;
  }
}

export default Bridger;
